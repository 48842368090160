// mixins
import withPermissions from "@/mixins/withPermissions"
import withConfirmation from "@/mixins/withConfirmation"

export default {
  mixins: [withConfirmation, withPermissions],

  watch: {
    hasUnsavedChanges(useConfirm) {
      this.setDirty(useConfirm)
      this.setLogoutConfirm(useConfirm)
    }
  },

  methods: {
    handleClickBack() {
      this.$conditionalConfirm({
        useConfirm: this.useConfirm,
        handler: () => {
          this.setDirty(false)
          this.$emit("back")
        }
      })
    }
  },

  computed: {
    useConfirm() {
      return this.hasEditPermission() && this.hasUnsavedChanges
    }
  }
}
