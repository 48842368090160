import { api, i18n } from "@/config"
import { showSuccessToast } from "@/helpers/toasts"
import { handleFailedResponse } from "@/helpers/common"

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async ({ commit }, params) => {
    try {
      const { data } = await api.post(baseURI, { car_model: params })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.car_class_settings.car_model_created") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit }, { id, ...params }) => {
    try {
      const { data } = await api.put(`${baseURI}/${id}`, params)
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.car_class_settings.car_model_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})
