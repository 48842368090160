import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import { map } from "lodash-es"

import { buildCommonState } from "../../shared"
import { extractMutations, defaultFiltersObject } from "../shared"
import extractActions from "./actions"

const mapFilters = filters => {
  return {
    car_type_ids: map(filters.carTypes, "id"),
    search_value: filters.searchValue
  }
}

const baseURI = "/companies/car_models"

const store = new StoreItemsModule({
  baseURI: baseURI,
  presetActions: ["fetch", "delete"],
  withFilters: defaultFiltersObject(),
  withSorting: true,
  withPagination: true,
  mapFilters
})

store.mergeState(buildCommonState())

store.mergeMutations(extractMutations())
store.mergeActions(extractActions({ baseURI }), withLoading)

export default store
